import { Component } from "react";
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';

import fotoPessoa from '../../assets/images/foto-pessoa.png';
import banner from '../../assets/images/banner.png';
import foto1 from '../../assets/images/foto1.png';
import foto2 from '../../assets/images/foto2.png';
import logoPreta from '../../assets/images/logoPreta.png';
import logoBranca from '../../assets/images/logoBranca.png';
import { Navigate } from 'react-router-dom';

import { Accordion, AccordionTab } from 'primereact/accordion';

export default class Hotsite extends Component {

    TelaEnum = {
        Principal: "Principal",
        PoliticaPrivacidade: "PoliticaPrivacidade",
        TermoUso: "TermoUso",
        Login: "Login",
    }

    state = {
        sidebarVisivel: false,
        tela: this.TelaEnum.Principal,
    }

    render() {
        return <div>
            {this.state.tela === this.TelaEnum.Login && <Navigate to="/login" />}
            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#sobre">Sobre</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#funcionalidades">Funcionalidades</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#depoimentos">Depoimentos</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#perguntas-frequentes">Perguntas frequentes</a>

                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Login })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#">Login</a>
                </div>
            </Sidebar>

            <header className="h-header-container">
                <img src={logoPreta} alt="logo" />

                <nav>
                    <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Sobre</a>
                    <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Funcionalidades</a>
                    <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Depoimentos</a>
                    <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Perguntas frequentes</a>

                    <a href="#" onClick={() => this.setState({ tela: this.TelaEnum.Login })}>Login</a>

                    <button onClick={() => this.setState({ sidebarVisivel: true })}>
                        <i className="pi pi-bars"></i>
                    </button>
                </nav>
            </header>

            {this.state.tela === this.TelaEnum.Principal &&
                <main className="h-main-container">
                    <img className="h-slide-img" src={banner} alt="imagem principal" />

                    <section className="h-sobre-container" id="sobre">
                        <div>
                            <i className="pi pi-building" style={{ fontSize: 30 }}></i>
                            <h2>Quem somos</h2>
                            <p>
                                O Lirida veio com o intuito de acelerar o seu crescimento na área da tecnologia! Na área de desenvolvimento de software! Com objetivo de gerar novos negócios e incentivar o empreendedorismo na área da tecnologia, inovação e novas startups e soluções do mercado.
                            </p>
                        </div>
                        <div>
                            <i className="pi pi-cog" style={{ fontSize: 30 }}></i>
                            <h2>Como funciona</h2>
                            <p>
                                Toda a base tecnológica que você precisa para desenvolver sua plataforma do zero está aqui! O Lirida conta com todos os recursos necessários para fazer toda parte da base do desenvolvimento até a gestão completa da plataforma. A solução que te auxilia desde a concepção da ideia até a gestão administrativa tecnológica após o lançamento.
                            </p>
                        </div>
                        <div>
                            <i className="pi pi-users" style={{ fontSize: 30 }}></i>
                            <h2>Para quem</h2>
                            <p>
                                Feito para você desenvolvedor de software que quer dá o próximo passo na carreira e virar um empresário na área da tecnologia construindo um startup! Acelerar o processo de desenvolvimento e toda gestão e parametrização da solução que você vai construir!
                            </p>
                        </div>
                    </section>

                    <section className="h-func-container" id="funcionalidades">
                        <div>
                            <p>
                                A inovação tecnológica na área de desenvolvimento de software.
                            </p>
                            <img src={foto1} alt="imagem flutuante" />
                        </div>

                        <div>
                            <p>
                                O acelerador de processos! O acelerador da sua carreira!
                            </p>
                        </div>

                        <div>
                            <img src={foto2} alt="imagem flutuante" />
                            <p>
                                A solução que você precisava para desenvolver sua ideia
                            </p>
                        </div>
                    </section>

                    <section className="h-depo-container" id="depoimentos">
                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Kevin (Programador):
                                </h2>
                                <p>
                                    O Lirida foi solução que precisava para desenvolver minhas ideias, agora posso criar meus próprios produtos do zero.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Patrick (Programador):
                                </h2>
                                <p>Lirida facilitou muito os meu projetos. Agora consigo entregar um software para o meu cliente na metade do tempo.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Cassiano (Programador):
                                </h2>
                                <p>
                                    Mesmo depois de já ter começado o projeto da minha plataforma, eu decidi migrar tudo para o Lirida. É a base tecnológica perfeita!
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="h-faq-container" id="perguntas-frequentes">
                        <h2 className="h-titulo-underline" style={{ textAlign: 'center' }}>Perguntas frequentes</h2>

                        <Accordion multiple>
                            <AccordionTab header="Preciso pagar alguma mensalidade para usar a plataforma?">
                                <p>Não, para utilizar nossa plataforma e ter acesso aos recursos é totalmente livre de mensalidade.</p>
                            </AccordionTab>

                            <AccordionTab header="Depois que criei a conta, posso editar minhas informações?">
                                <p>Sim, suas informações ficam disponíveis para edição quando você quiser</p>
                            </AccordionTab>

                            <AccordionTab header="Como faço para me cadastrar na plataforma?">
                                <p>Entre em contato conosco no email atendimento@lirida.com.br</p>
                            </AccordionTab>

                            <AccordionTab header="Eu posso cancelar ou excluir minha conta?">
                                <p>Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.</p>
                            </AccordionTab>

                            <AccordionTab header="Preciso passar por alguma análise?">
                                <p>Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.</p>
                            </AccordionTab>

                        </Accordion>
                    </section>
                </main>
            }

            {this.state.tela === this.TelaEnum.PoliticaPrivacidade &&
                <main className="h-main-container" style={{ padding: '90px 30px 30px 30px' }}>
                    <section className="h-politica-privacidade-container">
                        <h1 className="h-titulo-underline">Política de privacidade</h1>
                        <p className="h-texto-normal">
                            Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Lirida. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Lirida serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da Lirida pressupõe a aceitação deste acordo de privacidade. A equipa da Lirida reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
                        </p>
                        <h2 className="h-titulo-paragrafo">Ligações a sites de terceiros</h2>
                        <p className="h-texto-normal">
                            A Lirida possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
                        </p>
                    </section>
                </main>
            }

            {this.state.tela === this.TelaEnum.TermoUso &&
                <main className="h-main-container" style={{ padding: '90px 30px 30px 30px' }}>
                    <section className="h-politica-privacidade-container">
                        <h1 className="h-titulo-underline">Termo de uso Lirida</h1>
                        <p className="h-texto-normal">
                            Iniciando o uso do website da Lirida e dos serviços disponibilizados por ele, você concorda e estará sujeito a todos os termos, políticas e condições gerais descritas abaixo.
                        </p>

                        <h2 className="h-titulo-paragrafo">1. Objeto</h2>

                        <p className="h-texto-normal">
                            1.1 A Lirida é uma ferramenta online de captação de recursos financeiro de forma simples e fácil.
                        </p>
                        <p className="h-texto-normal">
                            1.2 A Lirida possibilita a criação da página pública de projetos, possui opção para recebimento de doações e vendas online através de uma aplicação web na internet, acessível a partir do endereço.
                            <span><a href='https://www.lirida.com.br' ref="noreferrer" target='_blank'>https://www.lirida.com.br</a></span>
                        </p>

                        <h2 className="h-titulo-paragrafo">2. Propriedade</h2>
                        <p className="h-texto-normal">
                            2.1 A Lirida foi desenvolvido pela CROSSSYSTEM TECNOLOGIA DA INFORMAÇÃO LTDA. CNPJ 20.706.163./0001-00, e a propriedade do sistema é, e será a qualquer tempo, da CROSSSYSTEM.
                        </p>
                        <p className="h-texto-normal">
                            2.2 O Usuário concorda que o uso da Lirida não significa, nem significará, aquisição de qualquer direito sobre o mesmo, não podendo explorá-lo comercialmente ou comercializá-lo.
                        </p>
                        <p className="h-texto-normal">
                            2.3 O Usuário declara estar ciente de que a Lirida contém informações confidenciais protegidas pela Lei de Proteção sobre Propriedade Intelectual e por direitos autorais.
                        </p>

                        <h2 className="h-titulo-paragrafo">3. Serviço</h2>
                        <p className="h-texto-normal">
                            3.1 Para utilização da Lirida solicitamos os dados de identificação do Usuário para fins legais.
                        </p>

                        <p className="h-texto-normal">
                            3.2 A Lirida é comercializado aos clientes a partir de um modelo de cobrança de tarifas por captação financeira confirmada nos projetos gerenciados. Os valores das tarifas e os prazos são divulgados no site <span><a href='https://www.lirida.com.br' rel="noreferrer" target='_blank'>https://www.lirida.com.br</a></span> e podem ser alterados a qualquer momento.
                        </p>

                        <p className="h-texto-normal">
                            3.3 É reservado à CROSSSYSTEM o direito de modificar a Lirida, acrescentar ou excluir funcionalidades, acrescentar serviços, alterar layout, etc, por qualquer motivo e sem aviso prévio, sem que isso implique qualquer responsabilidade em relação ao Usuário ou a terceiros.
                        </p>
                        <p className="h-texto-normal">
                            3.4 Os dados dos Usuários da Lirida são armazenados em servidores, que seguem políticas e normas de segurança da informação de primeiro nível, realizando cópias de segurança dos dados (backups) periodicamente e preparados para recuperá-los em caso de falhas.
                        </p>
                        <p className="h-texto-normal">
                            3.5 A CROSSSYSTEM também realiza diariamente cópias de segurança dos dados (backups) de todos os Usuários da Lirida e também está preparada para recuperá-los em caso de falhas. Essa redundância de armazenamento representa nosso segundo nível de segurança dos dados da Lirida.
                        </p>
                        <p className="h-texto-normal">
                            3.6 As informações inseridas na Lirida são de total responsabilidade do Usuário.
                        </p>
                        <p className="h-texto-normal">
                            3.7 O Usuário se compromete a utilizar a Lirida em conformidade com lei e regulamento local, estadual e nacional.
                        </p>
                        <p className="h-texto-normal">
                            3.8 O Usuário se compromete a não utilizar a Lirida para meios ilícitos ou que prejudique interesse de terceiros, respeitando a ética, a moral e os bons costumes.
                        </p>

                        <p className="h-texto-normal">
                            3.9 Para acessar sua conta da Lirida, o Usuário compromete-se a utilizar uma senha forte (difícil de ser descoberta) e não divulgá-la para outras pessoas, sendo responsável por sua confidencialidade.
                        </p>

                        <p className="h-texto-normal">
                            3.10 O Usuário declara expressamente ser responsável por sua conta na Lirida e aceita voluntariamente que o uso do software ocorra sob sua única e exclusiva responsabilidade.
                        </p>

                        <p className="h-texto-normal">
                            3.11 Por ser um portal que permite o cadastramento de informações sobre projetos, a CROSSSYSTEM analisa e avalia a integridade de cada projeto publicado, podendo ser bloqueado caso seja identificado (i) informações falsas ou errôneas a respeito de projeto divulgadas no Website; (ii) problemas relativos à administração destes projetos, incluindo, mas não se limitando a atrasos, falta de qualidade, disparidades entre o projeto e o anúncio, projetos cadastrados que não correspondam às expectativas dos participantes.
                        </p>

                        <p className="h-texto-normal">
                            3.12 Para cancelamento da conta, o proprietário da mesma deverá realizar a solicitação através do email relacionamento@Lirida.com.br.
                        </p>

                        <p className="h-texto-normal">
                            3.13 Para cancelamento de um projeto, a Organização deverá acessar o painel administrativo, com seu usuário e senha, e realizar a inativação do Projeto.
                        </p>

                        <h2 className="h-titulo-paragrafo">4. Devolução</h2>
                        <p className="h-texto-normal">
                            É possível realizar a devolução do valor doado no prazo máximo de 60 dias, enviado a solicitação para o email relacionamento@Lirida.com.br.
                        </p>

                        <p className="h-texto-normal">
                            4.1 Boleto bancário<br />
                            Para receitas realizadas com boleto bancário, o doador deve fornecer os seguintes dados de quem realizou o pagamento:<br /><br />
                            - Nome completo;<br />
                            - Número do CPF;<br />
                            - Nome do banco;<br />
                            - Número da conta bancária;<br />
                            - Número da agência;<br />
                            - Valor a ser devolvido;<br />
                            Depois, é só esperar até 10 dias úteis para receber a restituição na sua conta corrente.<br />
                        </p>

                        <p className="h-texto-normal">
                            4.2 Cartão de crédito<br />
                            para receitas realizadas com cartão de crédito, solicitaremos o estorno do débito à administradora do seu cartão. O prazo para o estorno das parcelas poderá ocorrer em até 2 faturas após o pedido do estorno. Lembre-se que esse procedimento é de responsabilidade da administradora do cartão. Deverá informar os seguintes dados:<br /> <br />
                            - Nome completo;<br />
                            - Número do CPF;<br />
                            - Valor a ser estornado;<br />
                        </p>
                        <h2 className="h-titulo-paragrafo">5. Garantias e Responsabilidade</h2>
                        <p className="h-texto-normal">
                            5.1 O uso da Lirida é fornecido sem garantia implícita ou expressa quanto a erros, perda de informações ou qualquer outro evento causado pela má utilização por parte do Usuário.
                        </p>

                        <p className="h-texto-normal">
                            5.2 A CROSSSYSTEM não garante que a Lirida atenda as necessidades particulares do Usuário.
                        </p>
                        <p className="h-texto-normal">
                            5.3 O Usuário declara estar ciente que a CROSSSYSTEM não tem, nem terá, qualquer responsabilidade, perante ao Usuário ou a terceiros, pelos prejuízos, danos patrimoniais ou morais de qualquer natureza resultante da má utilização da Lirida.
                        </p>
                        <p className="h-texto-normal">
                            5.4 Quando for solicitada transferência bancária de valores pelo usuário, a CROSSSYSTEM se responsabiliza e se compromete a realizar a mesma na conta informada pelo usuário em até 3 dias úteis, respeitando os valores solicitados e o saldo da conta do usuário, que se refere aos valores recebidos e compensados das receitas em projetos, já descontadas as tarifas cobradas pelo serviço, descritas no item 3.2 deste termo. O prazo de compensação das doações ocorre da seguinte forma:
                        </p>

                        <p className="h-texto-normal">
                            5.4.1 Boleto: 1 dia após a notificação de pagamento enviado pelo banco. O Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
                        </p>


                        <p className="h-texto-normal">
                            5.4.2 Cartão de Crédito: compensação conforme parcelamento (Ex: 30, 60, 90, 120 dias, etc). Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
                        </p>

                        <h2 className="h-titulo-paragrafo">6. Foro</h2>
                        <p className="h-texto-normal">
                            6.1 As Partes desenvolverão todos os esforços no sentido de resolver, amigavelmente, todas as controvérsias ou divergências que, porventura, forem decorrentes deste Termo.
                        </p>
                        <p className="h-texto-normal">
                            6.2 Não obstante o disposto no item anterior, o Usuário, ao aceitar este Termo, concorda em eleger o foro da comarca do Rio de Janeiro, no Estado do Rio de Janeiro, como competente para dirimir qualquer dúvida ou controvérsia porventura oriunda deste Termo e/ou da utilização do Sistema e, expressamente, renuncia a qualquer outro.
                        </p>

                        <h2 className="h-titulo-paragrafo">7 . Disposições Finais</h2>
                        <p className="h-texto-normal">
                            7.1 O Usuário concorda em receber, via e-mail, informativos do Lirida e da CROSSSYSTEM.
                        </p>

                        <p className="h-texto-normal">
                            7.2 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, mediante notificação prévia. Estando o Usuário de acordo, será feito um termo aditivo de comum acordo entre as partes.
                        </p>
                        <p className="h-texto-normal">
                            7.3 O Usuário aceita que a CROSSSYSTEM poderá, ao seu critério, alterar a prestação de serviços da Lirida, interromper temporária ou definitivamente, mediante aviso prévio ao Usuário.
                        </p>
                        <p className="h-texto-normal">
                            7.4 A violação de qualquer condição estabelecida neste termo ensejará a imediata exclusão do Usuário do cadastro da Lirida, ficando a partir de então suspenso qualquer acesso do Usuário às áreas restritas do Website, sem prejuízo do direito da CROSSSYSTEM e dos terceiros prejudicados de exigirem a reparação dos danos eventualmente causados independentemente de prévia notificação formal.
                        </p>

                        <p className="h-texto-normal">
                            7.5 O Usuário será o único, direto e exclusivo responsável por todos os danos, infrações, descumprimento legais e atos de qualquer natureza, que venha a causar a terceiros ou outros quando da utilização do sistema objeto deste instrumento. Na hipótese da CROSSSYSTEM vir a ser demandada em juízo por dano ocasionado pelo Usuário, ser-lhe-á lícito alegar sua ilegitimidade passiva, nomeá-lo à autoria, denunciá-lo à lide etc., enfim, praticar todos os atos necessários para exercer seu direito de regresso, servindo o presente instrumento como título executivo extrajudicial de todos e quaisquer valores eventualmente despendidos pela CROSSSYSTEM para fazer frente a indenizações, despesas judiciais etc., sendo que estes deverão ser atualizados na forma da lei.
                        </p>

                        <p className="h-texto-normal">
                            7.6 O Usuário declara que leu e concordou com todos os termos acima citados.
                        </p>
                    </section>
                </main>
            }

            <footer className="h-footer-container">
                <section>
                    <div>
                        <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Quem somos</a>
                        <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Funcionalidade</a>
                        <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Depoimentos</a>
                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Perguntas frequentes</a>

                        <a href="#" onClick={() => this.setState({ tela: this.TelaEnum.Login })}>Login</a>
                    </div>
                    <div>
                        <a href="#" onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            this.setState({ tela: this.TelaEnum.PoliticaPrivacidade });
                        }}>
                            Política de privacidade
                        </a>
                        <a href="#" onClick={() => {
                            this.setState({ tela: this.TelaEnum.TermoUso });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>
                            Termo de uso
                        </a>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/liridaoficial" target='_blank'>Facebook</a>
                        <a href="https://www.instagram.com/liridaoficial/" target='_blank'>Instagram</a>
                    </div>
                </section>
                <img src={logoBranca} alt="logo rodapé" />
            </footer>
        </div>
    }
}